import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { Grid, Typography } from '@material-ui/core';
import { BaseProps } from '../typings/types';
import Layout from '../components/layout';
import FooterButton from '../components/footerButton';

import { Location } from 'history';
import SelectButtonGroup from '../components/selectButtonGroup';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import { useAuthContext } from '../components/authContext';
import { setUserLocation, getLocations } from '../services/api';

export interface SetLocationState {
  location: string | undefined;
  locations: string[];
  setLocation: (newLocation: string) => void;
  submit: () => void;
}

export const useSetLocation = (
  navigationLocation: Location<{ location?: string }>
): SetLocationState => {
  const pathname = navigationLocation.pathname;
  useAuthRouteProtection(pathname);
  const { apiUser, changeLocation, getUser } = useAuthContext();
  const [location, setLocation] = useState<string | undefined>();

  useEffect(() => {
    setLocation(!!apiUser && !!apiUser.location ? apiUser.location : undefined);
  }, [apiUser]);

  const submit = async (): Promise<void> => {
    if (location === undefined) return;
    return setUserLocation(getUser, location).then((newApiUser): void => {
      changeLocation(newApiUser.location as string);
      navigate('/home');
    });
  };

  const [locations, setLocations] = useState<string[]>([]);
  useEffect(() => {
    getLocations(getUser).then((newLocations: string[]) => {
      setLocations(newLocations);
    });
  }, [getUser]);

  return {
    location,
    locations,
    setLocation,
    submit,
  };
};

interface PureSetLocationProps {
  existingLocation?: string;
  locations: string[];
  location: string | undefined;
  setLocation: (location: string) => void;
  submit: () => void;
}

export const PureSetLocation: React.FC<PureSetLocationProps> = ({
  locations,
  location,
  setLocation,
  submit,
}) => {
  return (
    <Layout navigateBackToPage={null} showUsername={true}>
      <Typography variant="h4" align="center">
        Select location
      </Typography>
      <Typography variant="subtitle1">
        You can change this from the menu at any time
      </Typography>
      <Grid alignContent="flex-start" justify="space-between" container>
        <SelectButtonGroup
          xsCols={4}
          id="user-locations"
          noLabel
          options={locations}
          value={location}
          maxDisplayed={30}
          onChange={setLocation}
        />
      </Grid>
      <FooterButton onClick={submit}>SUBMIT</FooterButton>
    </Layout>
  );
};

interface SetLocationProps extends BaseProps {
  location: Location<{ location?: string }>;
}

export const SetLocation: React.FC<SetLocationProps> = ({
  location: navigationLocation,
}) => {
  const setLocationState = useSetLocation(navigationLocation);
  return <PureSetLocation {...setLocationState} />;
};

export default SetLocation;

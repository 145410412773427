import { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useAuthContext } from '../components/authContext';

const useAuthRouteProtection = (pathname: string): void => {
  const { loggedIn, loggingIn, apiUser } = useAuthContext();
  useEffect(() => {
    if (!loggingIn) {
      if (!loggedIn) {
        if (pathname !== '/') {
          navigate('/', {
            replace: true,
          });
        }
      } else {
        if (apiUser) {
          if (
            typeof apiUser.location !== 'string' &&
            pathname !== '/set-location'
          ) {
            navigate('/set-location', {
              replace: true,
            });
          } else if (pathname === '/') {
            navigate('/home', {
              replace: true,
            });
          }
        }
      }
    }
  }, [loggedIn, loggingIn, pathname, apiUser]);
};

export default useAuthRouteProtection;
